<template>
  <main
  style="overflow: hidden;"
    @click="
      () => {
        showLeftSelet = false;
        this.closeAllRightSelet();
      }
    "
  >
    <div class="gradient-bg"></div>
    <div style="position: relative">
      <section
        id="index_main"
        class="main-section withheader darkbg"
        key="index_main"
      >
        <Iheader></Iheader>

        <!-- changeBox 交换框 -->
        <div class="wrapper">
          <deep-change-box></deep-change-box>
        </div>
      </section>
      <!-- <section
      class="advantages-section"
      id="advantages_section"
      key="advantages"
      v-if="false"
    >
      <div class="wrapper clrfix">
        <div class="col">
          <h3 class="notmobile">{{ $t("index.advantages0Name") }}</h3>
          <div class="advantages-icon">
            <span class="ico adv-time-0"
              ><i class="ico adv-time-1" :data-value="leftCoin.iconName"></i
            ></span>
          </div>
          <div class="advantages-descr">
            <h3 class="onlymobile">{{ $t("index.advantages0Name") }}</h3>
            <p>{{ $t("index.advantages0Descr") }}</p>
          </div>
        </div>
        <div class="col">
          <h3 class="notmobile">{{ $t("index.advantages1Name") }}</h3>
          <div class="advantages-icon">
            <span class="ico adv-money-0"
              ><i class="ico adv-money-1" :data-value="leftCoin.iconName"></i
            ></span>
          </div>
          <div class="advantages-descr">
            <h3 class="onlymobile">{{ $t("index.advantages1Name") }}</h3>
            <p>{{ $t("index.advantages1Descr") }}</p>
          </div>
        </div>
        <div class="col">
          <h3 class="notmobile">{{ $t("index.advantages2Name") }}</h3>
          <div class="advantages-icon">
            <span class="ico adv-exchange-0"
              ><i
                class="ico adv-exchange-1"
                :data-value="leftCoin.iconName"
              ></i
            ></span>
          </div>
          <div class="advantages-descr">
            <h3 class="onlymobile">{{ $t("index.advantages2Name") }}</h3>
            <p>{{ $t("index.advantages2Descr") }}</p>
          </div>
        </div>
      </div>
    </section> -->
      <!-- <section class="recent-section" key="recent" v-if="false">
      <div class="wrapper clrfix">
        <h2 class="center">Recent transactions</h2>
        <ul class="recent-list" id="recent_list">
          <li>
            <div class="recent-time">
              <time timestamp="1653114879">3 minutes ago</time>
            </div>
            <div class="dir-from">
              <div class="coin-value">500 USDT</div>
              <div class="coin-ico svgcoin usdttrc"></div>
            </div>
            <div class="dir-arrow">
              <span class="ico arrow"></span>
            </div>
            <div class="dir-to">
              <div class="coin-ico svgcoin eth"></div>
              <div class="coin-value">ETH</div>
            </div>
            <div class="recent-howlong">
              <i class="ico timer"></i
              ><span class="recent-timer" data-time="7">7 sec</span>
            </div>
          </li>
          <li>
            <div class="recent-time">
              <time timestamp="1653114850">4 minutes ago</time>
            </div>
            <div class="dir-from">
              <div class="coin-value">500 USDT</div>
              <div class="coin-ico svgcoin usdttrc"></div>
            </div>
            <div class="dir-arrow">
              <span class="ico arrow"></span>
            </div>
            <div class="dir-to">
              <div class="coin-ico svgcoin doge"></div>
              <div class="coin-value">DOGE</div>
            </div>
            <div class="recent-howlong">
              <i class="ico timer"></i
              ><span class="recent-timer" data-time="21">21 sec</span>
            </div>
          </li>
          <li>
            <div class="recent-time">
              <time timestamp="1653114664">7 minutes ago</time>
            </div>
            <div class="dir-from">
              <div class="coin-value">5 LTC</div>
              <div class="coin-ico svgcoin ltc"></div>
            </div>
            <div class="dir-arrow">
              <span class="ico arrow"></span>
            </div>
            <div class="dir-to">
              <div class="coin-ico svgcoin usdttrc"></div>
              <div class="coin-value">USDT</div>
            </div>
            <div class="recent-howlong">
              <i class="ico timer"></i
              ><span class="recent-timer" data-time="249">4 min</span>
            </div>
          </li>
          <li>
            <div class="recent-time">
              <time timestamp="1653114563">8 minutes ago</time>
            </div>
            <div class="dir-from">
              <div class="coin-value">0.32 ETH</div>
              <div class="coin-ico svgcoin eth"></div>
            </div>
            <div class="dir-arrow">
              <span class="ico arrow"></span>
            </div>
            <div class="dir-to">
              <div class="coin-ico svgcoin usdtbsc"></div>
              <div class="coin-value">USDT</div>
            </div>
            <div class="recent-howlong">
              <i class="ico timer"></i
              ><span class="recent-timer" data-time="114">1 min</span>
            </div>
          </li>
          <li>
            <div class="recent-time">
              <time timestamp="1653114043">17 minutes ago</time>
            </div>
            <div class="dir-from">
              <div class="coin-value">45 USDT</div>
              <div class="coin-ico svgcoin usdttrc"></div>
            </div>
            <div class="dir-arrow">
              <span class="ico arrow"></span>
            </div>
            <div class="dir-to">
              <div class="coin-ico svgcoin btc"></div>
              <div class="coin-value">BTC</div>
            </div>
            <div class="recent-howlong">
              <i class="ico timer"></i
              ><span class="recent-timer" data-time="13">13 sec</span>
            </div>
          </li>
          <li>
            <div class="recent-time">
              <time timestamp="1653113824">21 minutes ago</time>
            </div>
            <div class="dir-from">
              <div class="coin-value">3728 BUSD</div>
              <div class="coin-ico svgcoin busdbsc"></div>
            </div>
            <div class="dir-arrow">
              <span class="ico arrow"></span>
            </div>
            <div class="dir-to">
              <div class="coin-ico svgcoin btc"></div>
              <div class="coin-value">BTC</div>
            </div>
            <div class="recent-howlong">
              <i class="ico timer"></i
              ><span class="recent-timer" data-time="32">32 sec</span>
            </div>
          </li>
          <li>
            <div class="recent-time">
              <time timestamp="1653113773">21 minutes ago</time>
            </div>
            <div class="dir-from">
              <div class="coin-value">9.58815 DASH</div>
              <div class="coin-ico svgcoin dash"></div>
            </div>
            <div class="dir-arrow">
              <span class="ico arrow"></span>
            </div>
            <div class="dir-to">
              <div class="coin-ico svgcoin bch"></div>
              <div class="coin-value">BCH</div>
            </div>
            <div class="recent-howlong">
              <i class="ico timer"></i
              ><span class="recent-timer" data-time="409">6 min</span>
            </div>
          </li>
          <li>
            <div class="recent-time">
              <time timestamp="1653113731">22 minutes ago</time>
            </div>
            <div class="dir-from">
              <div class="coin-value">6.17187 ETH</div>
              <div class="coin-ico svgcoin eth"></div>
            </div>
            <div class="dir-arrow">
              <span class="ico arrow"></span>
            </div>
            <div class="dir-to">
              <div class="coin-ico svgcoin usdttrc"></div>
              <div class="coin-value">USDT</div>
            </div>
            <div class="recent-howlong">
              <i class="ico timer"></i
              ><span class="recent-timer" data-time="65">1 min</span>
            </div>
          </li>
          <li>
            <div class="recent-time">
              <time timestamp="1653113723">22 minutes ago</time>
            </div>
            <div class="dir-from">
              <div class="coin-value">1.19 ETH</div>
              <div class="coin-ico svgcoin eth"></div>
            </div>
            <div class="dir-arrow">
              <span class="ico arrow"></span>
            </div>
            <div class="dir-to">
              <div class="coin-ico svgcoin btc"></div>
              <div class="coin-value">BTC</div>
            </div>
            <div class="recent-howlong">
              <i class="ico timer"></i
              ><span class="recent-timer" data-time="130">2 min</span>
            </div>
          </li>
          <li>
            <div class="recent-time">
              <time timestamp="1653113668">23 minutes ago</time>
            </div>
            <div class="dir-from">
              <div class="coin-value">0.33592 LTC</div>
              <div class="coin-ico svgcoin ltc"></div>
            </div>
            <div class="dir-arrow">
              <span class="ico arrow"></span>
            </div>
            <div class="dir-to">
              <div class="coin-ico svgcoin btcln"></div>
              <div class="coin-value">BTC</div>
            </div>
            <div class="recent-howlong">
              <i class="ico timer"></i
              ><span class="recent-timer" data-time="231">3 min</span>
            </div>
          </li>
        </ul>
      </div>
    </section> -->

      <!-- faq -->
      <section
        class="faq-section"
        style="background-color: transparent"
        key="faq"
      >
        <div class="wrapper clrfix">
          <h2
            class="center"
            style="
              font-family: Bebas;
              font-size: 20px;
              font-weight: 400;
              line-height: 26.46px;
              text-align: center;
              color: #ffffff;
            "
          >
            FAQ
          </h2>
          <ul
            style="
              border: 1px solid transparent;
              padding: 10px;
              border-radius: 10px;
              background: #0e1337;
              border: 2px solid #161b43;
            "
            class="mini-faq clrfix"
            id="my-ul"
          >
            <li>
              <h4>{{ $t("index.faq0NameDeep") }}</h4>
              <p>
                {{ $t("index.faq0ContentDeep") }}
              </p>
            </li>
            <li>
              <h4>{{ $t("index.faq1NameDeep") }}</h4>
              <p>
                {{ $t("index.faq1ContentDeep") }}
              </p>
              <p class="reviews-images" v-if="false">
                <a
                  rel="noopener noreferrer"
                  target="_blank"
                  class="svgreviews tw"
                  title="twitter.com"
                  href="https://twitter.com/fixedfloat"
                ></a>
                <a
                  rel="noopener noreferrer"
                  target="_blank"
                  class="svgreviews tg"
                  title="Telegram"
                  href="https://t.me/FixedFloat"
                ></a>
                <a
                  rel="noopener noreferrer"
                  target="_blank"
                  class="svgreviews medium"
                  title="medium.com"
                  href="https://medium.com/fixedfloat"
                ></a>
                <a
                  rel="noopener noreferrer"
                  target="_blank"
                  class="svgreviews instagram"
                  title="instagram.com"
                  href="https://www.instagram.com/fixedfloat/"
                ></a>
                <a
                  rel="noopener noreferrer"
                  target="_blank"
                  class="svgreviews reddit"
                  title="reddit.com"
                  href="https://www.reddit.com/user/FixedFloat/"
                ></a>
                <a
                  rel="noopener noreferrer"
                  target="_blank"
                  class="svgreviews bitcointalk"
                  title="bitcointalk.org"
                  href="https://bitcointalk.org/index.php?topic=5107342.new#new"
                ></a>
                <a
                  rel="noopener noreferrer"
                  target="_blank"
                  class="svgreviews bestchange"
                  title="bestchange.com"
                  href="https://www.bestchange.com/fixedfloat-exchanger.html"
                ></a>
                <a
                  rel="noopener noreferrer"
                  target="_blank"
                  class="svgreviews trustpilot"
                  title="trustpilot.com"
                  href="https://www.trustpilot.com/review/fixedfloat.com"
                ></a>
              </p>
            </li>
            <li>
              <h4>{{ $t("index.faq2NameDeep") }}</h4>
              <p>
                {{ $t("index.faq2ContentDeep") }}
              </p>
              <p v-if="false">
                •  1% if you opt for a <b>fixed rate</b><br />•  0.5% if you opt
                for a <b>floating rate</b>
              </p>
            </li>
          </ul>
          <div class="mini-faq-more" v-if="false">
            <a href="/#/faq"
              ><span>Go to page FAQ</span> <i class="ico arrow-forward"></i
            ></a>
          </div>
        </div>
      </section>
      <section class="recent-section" key="clrfix" v-if="false">
        <div class="wrapper clrfix">
          <h2 class="center">Recent posts</h2>
          <div class="blog-articles articles-news blog-index">
            <article class="blog-post blog-post-main">
              <div class="blog-post-img">
                <a href="/#/blog/news/weekly-2022-05-14"
                  ><img
                    src="/images//photo_2022-05-13_21-26-09_w700.jpg"
                    alt=""
                /></a>
              </div>
              <div class="blog-post-body">
                <header class="blog-post-head">
                  <a href="/#/blog/news/weekly-2022-05-14"
                    >Weekly news of cryptocurrencies</a
                  >
                  <div class="blog-time">
                    <time timestamp="1652529600">May 14, 2022</time>
                  </div>
                </header>
                <div class="blog-post-desc">
                  During this week (2022.05.08-2022.05.14) a lot of events
                  happened in the crypto community. We have prepared the most
                  interesting and informative news for you.
                </div>
              </div>
            </article>
            <article class="blog-post">
              <div class="blog-post-img">
                <a href="/#/blog/news/weekly-2022-05-07"
                  ><img
                    src="/images//photo_2022-05-06_13-12-03_w700.jpg"
                    alt=""
                /></a>
              </div>
              <div class="blog-post-body">
                <header class="blog-post-head">
                  <a href="/#/blog/news/weekly-2022-05-07"
                    >Weekly news of cryptocurrencies</a
                  >
                  <div class="blog-time">
                    <time timestamp="1651924800">May 7, 2022</time>
                  </div>
                </header>
              </div>
            </article>
            <article class="blog-post">
              <div class="blog-post-img">
                <a href="/#/blog/guides/how-to-exchange"
                  ><img src="/images//how-to-exchange_w700.jpg" alt=""
                /></a>
              </div>
              <div class="blog-post-body">
                <header class="blog-post-head">
                  <a href="/#/blog/guides/how-to-exchange"
                    >How to exchange cryptocurrencies on the FixedFloat?</a
                  >
                  <div class="blog-time">
                    <time timestamp="1638532800">December 3, 2021</time>
                  </div>
                </header>
              </div>
            </article>
            <article class="blog-post">
              <div class="blog-post-img">
                <a href="/#/blog/guides/how-to-avoid-scams-theft"
                  ><img src="/images//avoidscam_w700.jpg" alt=""
                /></a>
              </div>
              <div class="blog-post-body">
                <header class="blog-post-head">
                  <a href="/#/blog/guides/how-to-avoid-scams-theft"
                    >How to avoid scams and theft of your crypto?</a
                  >
                  <div class="blog-time">
                    <time timestamp="1635249600">October 26, 2021</time>
                  </div>
                </header>
              </div>
            </article>
            <article class="blog-post">
              <div class="blog-post-img">
                <a href="/#/blog/guides/sent-to-the-wrong-network"
                  ><img src="/images//recover_tokens_w700.jpg" alt=""
                /></a>
              </div>
              <div class="blog-post-body">
                <header class="blog-post-head">
                  <a href="/#/blog/guides/sent-to-the-wrong-network"
                    >How to recover tokens when sending to the wrong network?</a
                  >
                  <div class="blog-time">
                    <time timestamp="1631620800">September 14, 2021</time>
                  </div>
                </header>
              </div>
            </article>
          </div>
        </div>
      </section>

      <v-dialog></v-dialog>
    </div>
  </main>
</template>

<script>
import Iheader from "@/components/header.vue";
import colorList from "@/plugins/data/colors";
import deepChangeBox from "../../../components/deepChangeBox.vue";
export default {
  name: "homePage",
  components: {
    Iheader,
    deepChangeBox,
  },
  data() {
    return {
      isAllAddressValid: false,
      isPercentValid: true,
      tmpFocusValue: null,
      exchangeTimeArrProxy: null,
      exchangeTimeArrIndex: 0,
      //exchangeTime: '1 '+this.$t('index.hour') + ', '+this.$t('index.fee')+': 5%, '+this.$t('index.safe1'),
      //exchangeTime: '',
      exchangeFee: "5%",
      rightCoinList: [],
      searchText: "",
      currencyList: {
        coin: [],
        token: [],
        popular: [],
      },
      showLeftSelet: false,
      isRightOnFocus: false,
      leftCoin: {
        type: "coin",
        name: "",
        sortName: "",
        iconName: "",
        amount: 0,
        min_amount: 0,
      },
      formData: {
        sendNum: 0,
        receiveNum: "0",
        type: "float",
        address: "",
      },
      exchangePriceUsd: "0",
      readyToOrder: false,
      colorList,
    };
  },
  created() {
    this.getCurrencyList();
  },
  mounted() {
    this.getNotice();
  },
  watch: {
    exchangeFee() {
      this.getExchangePrice();
    },
    // "exchangeTime":{
    // handler(newVal){
    // console.log(newval)
    // this.exchangeFee = ['5%','6%','7%','8%','9%','10%'][this.exchangeTimeArr.indexOf(newVal)];
    // }

    // },
    "$route.query": {
      handler() {
        console.log(this.$route.query);
        // 执行代码部分
        let { from, to, lang } = this.$route.query;
        if (lang) {
          this.getNotice();
        }
        // if (from && to) {
        // this.currencyList.popular.forEach((item) => {
        // if (item[1] === from) {
        // this.chooseCoin("leftCoin", item, "coin", true);
        // } else if (item[1] === to) {
        // this.chooseCoin("rightCoin", item, "coin", true);
        // }
        // });
        // this.formData.sendNum = 0;
        // this.getExchangePrice(true);
        // this.$scrollToTop();
        // }
      },
    },
  },
  methods: {
    closeAllRightSelet() {
      this.rightCoinList.forEach(function (item, index, rightCoinList) {
        rightCoinList[index].showSelet = false;
      });
    },
    checkAllAddressValid() {
      var flag = false;
      this.rightCoinList.every(function (item) {
        if (!item.isValidAddress) {
          flag = false;
          return false;
        } else {
          flag = true;
        }
        return true;
      });
      return flag;
    },
    addRightCoin() {
      if (this.rightCoinList.length > 9) {
        return;
      }

      this.rightCoinList.every(function (item) {
        if (item.percent > 1) {
          item.percent -= 1;
          return false;
        }
      });
      this.addRightCoinNum();
      var last_item = this.rightCoinList[this.rightCoinList.length - 2];

      this.chooseCoinRight(
        this.rightCoinList[this.rightCoinList.length - 1],
        [last_item.name, last_item.sortName, last_item.iconName],
        last_item.type,
        1
      );
      return;
    },
    addRightCoinNum() {
      this.rightCoinList.push({
        name: "",
        type: "",
        percent: 0,
        rate: 0,
        amount: 0,
        amountText: "",
        sortName: "",
        iconName: "",
        showSelet: false,
        address: "",
        addressFocus: false,
        isValidAddress: false,
        validAddress: function () {
          this.isValidAddress = false;
          if (this.address == "" || this.address.length < 30) {
            return;
          }
          this.validAddressApi(this);
        },
        validAddressApi: (x) => {
          this.$api
            .validAddress({
              currency: x.sortName,
              address: x.address,
            })
            .then((res) => {
              x.isValidAddress = res.data.valid;
              x.addressFocus = false;
              this.isAllAddressValid = this.checkAllAddressValid();
            });
        },
      });
    },
    delRightCoinNum() {
      if (this.rightCoinList.length < 3) {
        return;
      }
      var xxx = this.rightCoinList.pop();
      this.rightCoinList[0].percent += xxx.percent;
      this.getExchangePrice();
    },
    getNotice() {
      var ts_last = this.$cookies.get("notice_ts");
      var ts = parseInt(new Date().getTime() / 1000);
      if (!ts_last) {
        this.$cookies.set("notice_ts", ts);
      } else {
        if (ts - ts_last < 3600 * 24) {
          return;
        } else {
          this.$cookies.set("notice_ts", ts);
        }
      }
      var lang_index = this.$cookies.get("langIndex");
      this.$api.getNotice().then((res) => {
        if (res.data.show) {
          this.$modal.show("dialog", {
            title: res.data.content[lang_index][0],
            text:
              res.data.content[lang_index][1] +
              res.data.scam_list.join("<br />"),
            buttons: [
              {
                title: res.data.content[lang_index][2],
                handler: () => {
                  this.$modal.hide("dialog");
                },
              },
            ],
          });
        }
      });
    },
    getCurrencyList() {
      this.addRightCoinNum();
      this.addRightCoinNum();
      this.$api.getCurrencyList().then((res) => {
        let currencyList = res.data;
        for (let key in currencyList) {
          currencyList[key].forEach((item) => {
            item[2] = item[2][1];
          });
        }
        this.currencyList = currencyList;
        this.chooseCoinLeft(this.currencyList.popular[4], "coin", true);
        this.chooseCoinRight(
          this.rightCoinList[0],
          this.currencyList.popular[0],
          "coin",
          60,
          true
        );
        this.chooseCoinRight(
          this.rightCoinList[1],
          this.currencyList.popular[1],
          "coin",
          40,
          true
        );
        this.getExchangePrice();
      });
    },
    getExchangePrice() {
      var total_percent = 0;
      var info = [];
      this.rightCoinList.every(function (item) {
        if (item.showSelet) {
          total_percent = 0;
          return false;
        }
        if (item.percent < 1) {
          total_percent = 0;
          return false;
        }
        total_percent += item.percent;
        info.push([item.sortName, item.percent]);
        return true;
      });
      console.log(total_percent, this.rightCoinList, info);
      if (total_percent != 100) {
        this.isPercentValid = false;
        return;
      }
      this.isPercentValid = true;

      this.$api
        .getExchangePriceDeep({
          from_currency: this.leftCoin.sortName,
          to_currency: JSON.stringify(info),
          from_qty: this.leftCoin.amount,
          duration: this.exchangeTime,
          //type: this.formData.type,
          // to_qty: "",
        })
        .then((res) => {
          this.leftCoin.amount = res.data.from.amount;
          this.leftCoin.min = res.data.from.min;
          this.rightCoinList.forEach(function (to_x, i, rightCoinList) {
            rightCoinList[i].amount = res.data.to[i][1];
            rightCoinList[i].rate = res.data.to[i][2];
            rightCoinList[i].amountText = "≈" + rightCoinList[i].amount;
          });
          this.exchangePriceUsd = res.data.usd;
        });
    },
    getExchangePriceByToQty(to_qty) {
      this.$api
        .getExchangePrice({
          from_currency: this.leftCoin.sortName,
          to_currency: this.rightCoin.sortName,
          type: this.formData.type,
          to_qty,
        })
        .then((res) => {
          this.exchangePrice = res.data;
          this.formData.receiveNum = "≈" + res.data.to.amount;
          this.formData.sendNum = res.data.from.amount;
        });
    },
    chooseCoinLeft(item, type, isDefault = false) {
      var old_sortName = this.leftCoin.sortName;
      this.searchText = "";
      this.leftCoin = {
        type: type,
        name: item[0],
        sortName: item[1],
        iconName: item[2],
        amount: 0,
      };
      this.showLeftSelet = false;
      this.closeAllRightSelet();
      //this.formData.sendNum = 0;
      if (old_sortName != this.leftCoin.sortName && !isDefault) {
        this.getExchangePrice();
      }
      //this.validAddress();
    },
    chooseCoinRight(x, item, type, percent = 0, isDefault = false) {
      var old_sortName = x.sortName;
      this.searchText = "";
      x.type = type;
      x.name = item[0];
      x.sortName = item[1];
      x.iconName = item[2];
      if (percent > 0) {
        x.percent = percent;
      }

      this.showLeftSelet = false;
      this.closeAllRightSelet();
      this.formData.sendNum = 0;
      x.validAddress();

      if (old_sortName != x.sortName && !isDefault) {
        this.getExchangePrice();
      }
    },
    changeSendNum() {
      if (this.leftCoin.amount < this.leftCoin.min - 0) {
        this.leftCoin.amount = this.leftCoin.min;
      }
      this.getExchangePrice();
    },
    getExchangeMake() {
      if (
        !this.checkAllAddressValid() ||
        !this.isPercentValid ||
        this.readyToOrder
      ) {
        return;
      }
      var right_arr = [];
      this.rightCoinList.forEach(function (item) {
        right_arr.push([item.sortName, item.percent, item.address]);
      });
      this.readyToOrder = true;
      this.$api
        .getExchangeMakeDeep({
          from_currency: this.leftCoin.sortName,
          to_currency: JSON.stringify(right_arr),
          from_qty: this.leftCoin.amount,
          duration: this.exchangeTime,
          fee: this.exchangeFee,
        })
        .then((res) => {
          this.$router.push({
            path: "/main/orderdeep",
            query: { orderId: res.data.order_id },
          });
        })
        .finally(() => {
          this.readyToOrder = false;
        });
    },
    exchangeCoin() {
      let temporary = { ...this.leftCoin };
      this.leftCoin = { ...this.rightCoin };
      this.rightCoin = { ...temporary };
      this.formData.sendNum = this.formData.receiveNum.replace("≈", "") - 0;
      this.getExchangePrice();
      this.validAddress();
    },
  },
  computed: {
    currencyListPopularSearch: function () {
      let arr = [];
      this.currencyList.popular.forEach((item) => {
        if (
          item[0].match(new RegExp(this.searchText, "ig")) ||
          item[1].match(new RegExp(this.searchText, "ig"))
        ) {
          arr.push(item);
        }
      });
      return arr;
    },
    currencyListCoinSearch: function () {
      let arr = [];
      this.currencyList.coin.forEach((item) => {
        if (
          item[0].match(new RegExp(this.searchText, "ig")) ||
          item[1].match(new RegExp(this.searchText, "ig"))
        ) {
          arr.push(item);
        }
      });
      return arr;
    },
    currencyListTokenSearch: function () {
      let arr = [];
      this.currencyList.token.forEach((item) => {
        if (
          item[0].match(new RegExp(this.searchText, "ig")) ||
          item[1].match(new RegExp(this.searchText, "ig"))
        ) {
          arr.push(item);
        }
      });
      return arr;
    },
    exchangeTimeArr: {
      get() {
        this.exchangeTimeArrProxy = [
          "1 " +
            this.$t("index.hour") +
            ", " +
            this.$t("index.fee") +
            ": 5%, " +
            this.$t("index.safe1"),
          "2 " +
            this.$t("index.hours") +
            ", " +
            this.$t("index.fee") +
            ": 6%, " +
            this.$t("index.safe1"),
          "4 " +
            this.$t("index.hours") +
            ", " +
            this.$t("index.fee") +
            ": 7%, " +
            this.$t("index.safe1"),
          "8 " +
            this.$t("index.hours") +
            ", " +
            this.$t("index.fee") +
            ": 8%, " +
            this.$t("index.safe2"),
          "12 " +
            this.$t("index.hours") +
            ", " +
            this.$t("index.fee") +
            ": 9%, " +
            this.$t("index.safe2"),
          "24 " +
            this.$t("index.hours") +
            ", " +
            this.$t("index.fee") +
            ": 10%, " +
            this.$t("index.safe3"),
        ];
        return [
          "1 " +
            this.$t("index.hour") +
            ", " +
            this.$t("index.fee") +
            ": 5%, " +
            this.$t("index.safe1"),
          "2 " +
            this.$t("index.hours") +
            ", " +
            this.$t("index.fee") +
            ": 6%, " +
            this.$t("index.safe1"),
          "4 " +
            this.$t("index.hours") +
            ", " +
            this.$t("index.fee") +
            ": 7%, " +
            this.$t("index.safe1"),
          "8 " +
            this.$t("index.hours") +
            ", " +
            this.$t("index.fee") +
            ": 8%, " +
            this.$t("index.safe2"),
          "12 " +
            this.$t("index.hours") +
            ", " +
            this.$t("index.fee") +
            ": 9%, " +
            this.$t("index.safe2"),
          "24 " +
            this.$t("index.hours") +
            ", " +
            this.$t("index.fee") +
            ": 10%, " +
            this.$t("index.safe3"),
        ];
      },
      set(newVal) {},
    },
    exchangeTime: {
      get() {
        return this.exchangeTimeArrProxy[this.exchangeTimeArrIndex];
      },
      set(newVal) {
        this.exchangeTimeArrIndex = newVal
          ? this.exchangeTimeArrProxy.indexOf(newVal)
          : 0;
        this.exchangeFee = ["5%", "6%", "7%", "8%", "9%", "10%"][
          this.exchangeTimeArrProxy.indexOf(newVal)
        ];
        //this.exchangeTime  = newVal;
      },
    },
  },
};
</script>
<style>
.style-chooser {
  font-size: 1.3em;
  width: 100%;
  background-color: #fff;
  border-radius: 0.6em;
  border: 0;
  padding: 2px;
}
.vs__dropdown-toggle {
  border-radius: 0.6em;
}
.style-chooser .vs__search::placeholder,
.style-chooser .vs__dropdown-toggle,
.style-chooser .vs__dropdown-menu {
  background: transparent;
  border: none;
  color: #fff;
  text-transform: lowercase;
  /* font-variant: small-caps; */
}

.style-chooser .vs__selected {
  color: #fff;
}

.style-chooser .vs__clear,
.style-chooser .vs__open-indicator {
  fill: #fff;
}
</style>
<style lang="less" scoped>
.self-scorll {
  &::-webkit-scrollbar {
    width: 5px;
  }
  /*滚动条里面小方块*/
  &::-webkit-scrollbar-thumb {
    background-color: rgba(255, 255, 255, 0.4);
    border-radius: 15px;
  }
  /*滚动条里面轨道*/
  &::-webkit-scrollbar-track {
    border-radius: 0;
    background-color: rgba(255, 255, 255, 0.05);
  }
}

#my-ul {
  // width: 385px;
  width: 100%;
}
#my-ul > li {
  background: linear-gradient(
    180deg,
    rgba(42, 70, 126, 0.122298) 0%,
    rgba(28, 45, 95, 0.196747) 100%
  );
  border-style: none;
  border-radius: 10px;
  // border: 1px solid red;
  // width: 385px !important;
  display: flex;
  flex-direction: column;

  // justify-content: space-between;
  h4 {
    color: #ffffff;
    font-family: Bebas !important;

    ;

    line-height: 24.12px;
    text-align: center;
    height: 80px;
    // border: 1px solid red;
    word-spacing: 7px;
  }
  > p {
    font-size: 16px;
    font-weight: 400;

    // text-align: left;

    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    text-align: left;
    color: #8c91af;
  }
}

@media screen and (max-width: 500px) {
  #my-ul > li {
    padding:38px;
    h4 {
      height: auto !important;
      font-family: Bebas;
      font-size: 16px;
      font-weight: 400;
      line-height: 21.17px;
      text-align: center;
      word-spacing: 10px;
    }

    p{
      // ;
      font-size: 12px;
      font-weight: 400;
      line-height: 12px;
      line-height: 22px;
      word-spacing: 4px;
    
    }
  }
}
</style>