<template>
  <div class="container-1">
    <!-- pc端 -->
    <div class="pc-box box-container">
      <img src="/img/cce-bg.svg" alt="" />
      <!-- 交换框 container -->
      <div class="input-container">
        <div class="img-wt"><img src="/img/bi.png" width="100%" alt="" /></div>
        <!-- 币输入框 第一个 -->
        <div
          class="col from-box"
          style="position: relative; /* border: 1px solid red !important; */"
        >
          <div
            class="input-wabbr toleft locked"
            id="left-coin-area"
            :data-value="leftCoin.iconName"
          >
            <!-- 下拉列表 -->
            <div
              class="ui-select-outer with-search hidden-label"
              :class="{
                active: showLeftSelet,
              }"
              :data-value="leftCoin.iconName"
            >
              <label class="ui-select" :data-value="leftCoin.iconName">
                <div class="ui-select-value">
                  <div
                    class="coin-ico svgcoin"
                    :class="leftCoin.iconName"
                  ></div>
                  <div class="coin-outer">
                    <span class="coin-name">{{ leftCoin.name }}</span>
                  </div>
                </div>
                <div class="ui-select-arrow"></div>
                <div class="ui-select-search">
                  <span class="ui-select-search-ico ico"></span>
                  <div class="ui-select-search-inner">
                    <input
                      type="text"
                      placeholder="Type a currency"
                      v-model="searchText"
                    />
                  </div>
                </div>
              </label>
              <div class="ui-select-list my-ui-list">
                <ul style="padding-right: 0px" class="self-scorll">
                  <li class="ui-select-separate">Popular currencies</li>
                  <li
                    class="ui-select-option hover"
                    v-for="item in currencyListPopularSearch"
                    :key="item[1]"
                    @click="chooseCoinLeft(item, 'popular')"
                    :data-value="item[2]"
                  >
                    <div class="coin-ico svgcoin" :class="item[2]"></div>
                    <div class="coin-outer">
                      <span class="coin-name"
                        >{{ item[0] }} ({{ item[1] }})</span
                      >
                    </div>
                  </li>
                  <li class="ui-select-separate">All currencies</li>
                  <li
                    class="ui-select-option hover"
                    v-for="item in currencyListCoinSearch"
                    :key="item[1] + 'coin'"
                    @click="chooseCoinLeft(item, 'coin')"
                    :data-value="item[2]"
                  >
                    <div class="coin-ico svgcoin" :class="item[2]"></div>
                    <div class="coin-outer">
                      <span class="coin-name"
                        >{{ item[0] }} ({{ item[1] }})</span
                      >
                    </div>
                  </li>
                  <li
                    class="ui-select-option hover"
                    v-for="item in currencyListTokenSearch"
                    @click="chooseCoinLeft(item, 'token')"
                    :key="item[1] + 'token'"
                    :data-value="item[2]"
                  >
                    <div class="coin-ico svgcoin" :class="item[2]"></div>
                    <div class="coin-outer">
                      <span class="coin-name"
                        >{{ item[0] }} ({{ item[1] }})</span
                      >
                    </div>
                  </li>
                </ul>
                <div class="ui-select-scroll-wrap hidden" data-height="100">
                  <div
                    class="ui-select-scroll"
                    style="top: 0px; height: 100%"
                  ></div>
                </div>
              </div>
            </div>
            <select id="select_currency_from" class="hidden">
              <option label="separate">Popular currencies</option>
              <option selected="" value="btc" data-symbol="BTC" data-sub="">
                Bitcoin
              </option>
            </select>

            <input
              class="input-amount"
              type="text"
              value=""
              id="select_amount_from"
              data-dir="from"
              maxlength="18"
              v-model="leftCoin.amount"
              @change="changeSendNum"
              style="width: 100%"
            />

            <!-- 聚焦显示最小值 -->
            <div id="select_maxmin_from" class="hint maxmin">
              <span>min:</span>&nbsp;<button
                @click="
                  () => {
                    leftCoin.amount = leftCoin.min;
                    getExchangePrice();
                  }
                "
                type="button"
                class="maxmin-value"
              >
                {{ leftCoin.min }} {{ leftCoin.sortName }}
              </button>
            </div>
            <span id="select_hinterror_from" class="hint error"></span>

            <!-- 图标 -->
            <label
              id="select_label_from"
              @click.stop="
                () => {
                  showLeftSelet = true;
                  closeAllRightSelet();
                }
              "
            >
              <span
                style="margin-right: 5px"
                class="coin-img svgcoin"
                :class="leftCoin.iconName"
              ></span>

          

              <span style="font-weight: 700;" v-if="leftCoin.sortName.indexOf('-') > -1"
                >{{ leftCoin.sortName.split("-")[0] }}
                <span> ({{ leftCoin.sortName.split("-")[1] }}) </span>
              </span>
              <span style="font-weight: 700;" v-else>{{ leftCoin.sortName }}</span>

              <span class="my-border"></span>
            </label>

            <div class="input-rate rev" id="select_info_from" v-show="false">
              <button
                class="btn-text tolightning"
                type="button"
                data-dir="from"
              >
                <span class="ico lightning" data-value="btcln"></span>
                <span>Try lightning!</span>
              </button>
            </div>

            <div class="input-rate" id="select_rate_from"></div>
          </div>
        </div>

        <!-- 交换框按钮 -->
        <div class="change-btn">
          <img width="17px" height="17px" src="/img/change-btn.svg" alt="" />
        </div>

      

        <!-- 币输入框 可添加 -->
        <div
          class="col"
          v-for="(rightCoin, index1) in rightCoinList"
          :key="index1"
          style="
            border: 1px solid transparent;
            height: 150px;
            margin-top: 30px;
            position: relative;
            background-color: transparent;
            padding: 0;
          "
        >
          <!-- <header>{{ $t("index.RECEIVE") }}</header> -->
          <div
            style="
              position: absolute;
              top: -25px;
              left: 10px;
              ;
              font-size: 12px;
              font-weight: 400;
              line-height: 14.47px;
              text-align: left;
            "
          >
            1 {{ rightCoin.sortName }} ≈ {{ rightCoin.rate }}
            {{ leftCoin.sortName }} ( {{ exchangeFee }}
            {{ $t("index.feeHint") }} )
          </div>
          <div>
            <div
              class="input-wabbr toright mobile-input-height"
              style="padding: 0; margin: 0"
              :data-value="rightCoin.iconName"
            >
              <!-- 左侧百分比 -->
              <div
                style="
                  width: 20%;
                  display: flex;
                  justify-content: center;
                  /* border: 1px solid blue; */
                  overflow: hidden;
                  background: #0000001f;
                  height: 60px;
                  border-radius: 10px !important;
                "
                class="left-height"
              >
                <input
                  type="text"
                  value=""
                  maxlength="2"
                  style="
                    width: 20px;
                    height: 100%;
                    text-align: center;
                    background-color: transparent;
                    outline-style: none;
                    border-style: none;
                    font-family: Bebas;
                    font-size: 14px;
                    font-weight: 700;
                    line-height: 18.52px;
                    text-align: right;
                    color: #f2c746;
                  "
                  class="right-percent"
                  v-model.number="rightCoin.percent"
                  :ref="'amount-percent-' + index1"
                  @focus="tmpFocusValue = rightCoin.percent"
                  @blur="
                    rightCoin.percent != tmpFocusValue && getExchangePrice()
                  "
                />
                <input
                  type="text"
                  value="%"
                  id="amount-percent"
                  maxlength="1"
                  readonly="readonly"
                  style="
                    width: 20px;
                    height: 100%;
                    color: #f2c746;
                    background-color: transparent;
                    outline-style: none;
                    border-style: none;
                    font-size: 18px;
                    font-weight: 700;
                    /* line-height: 16px; */
                    /* margin-top: -2px; */
                    font-family: Bebas;
                  "
                  class="right-percent-tag"
                  v-on:click="$refs['amount-percent-' + index1][0].focus()"
                />
              </div>

              <!-- 点击显示下拉框列表 -->
              <div
                class="ui-select-outer with-search hidden-label right-coin-area"
                style="left: 22%; width: 78%"
                :class="{
                  active: rightCoin.showSelet,
                }"
                :data-value="rightCoin.iconName"
              >
                <label
                  class="ui-select right-coin-select"
                  :data-value="rightCoin.iconName"
                >
                  <div class="ui-select-value">
                    <div class="coin-ico svgcoin eth"></div>
                    <div class="coin-outer">
                      <span class="coin-name">Ethereum</span>
                    </div>
                  </div>
                  <div class="ui-select-arrow"></div>
                  <div class="ui-select-search">
                    <span class="ui-select-search-ico ico"></span>
                    <div class="ui-select-search-inner">
                      <input
                        type="text"
                        placeholder="Type a currency"
                        v-model="searchText"
                      />
                    </div>
                  </div>
                </label>
                <div class="ui-select-list my-ui-list right-coin-select-list">
                  <ul style="padding-right: 0px" class="self-scorll">
                    <li class="ui-select-separate">Popular currencies</li>
                    <li
                      class="ui-select-option hover"
                      v-for="item in currencyListPopularSearch"
                      :key="item[1]"
                      @click="chooseCoinRight(rightCoin, item, 'popular')"
                      :data-value="item[2]"
                    >
                      <div class="coin-ico svgcoin" :class="item[2]"></div>
                      <div class="coin-outer">
                        <span class="coin-name"
                          >{{ item[0] }} ({{ item[1] }})</span
                        >
                      </div>
                    </li>
                    <li class="ui-select-separate">All currencies</li>
                    <li
                      class="ui-select-option hover"
                      v-for="item in currencyListCoinSearch"
                      :key="item[1] + 'coin'"
                      @click="chooseCoinRight(rightCoin, item, 'coin')"
                      :data-value="item[2]"
                    >
                      <div class="coin-ico svgcoin" :class="item[2]"></div>
                      <div class="coin-outer">
                        <span class="coin-name"
                          >{{ item[0] }} ({{ item[1] }})</span
                        >
                      </div>
                    </li>
                    <li
                      class="ui-select-option hover"
                      v-for="item in currencyListTokenSearch"
                      @click="chooseCoinRight(rightCoin, item, 'token')"
                      :key="item[1] + 'token'"
                      :data-value="item[2]"
                    >
                      <div class="coin-ico svgcoin" :class="item[2]"></div>
                      <div class="coin-outer">
                        <span class="coin-name"
                          >{{ item[0] }} ({{ item[1] }})</span
                        >
                      </div>
                    </li>
                  </ul>
                  <div class="ui-select-scroll-wrap hidden" data-height="100">
                    <div
                      class="ui-select-scroll"
                      style="top: 0px; height: 100%"
                    ></div>
                  </div>
                </div>
              </div>

              <!-- 不管 不知道是啥 -->
              <select id="select_currency_to" class="hidden">
                <option label="separate">Popular currencies</option>
                <option value="btc" data-symbol="BTC" data-sub="">
                  Bitcoin
                </option>
              </select>
              <!-- ------------------ -->

              <!-- 右边 输入框 -->
              <div
                style="
                  position: absolute;
                  width: 78%;
                  height: 60px;
                  top: 0;
                  right: 0;
                  background: #0000001f;
                  border-radius: 10px;
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                  padding: 0 10px;
                "
                :style="rightCoin.showSelet ? { opacity: 0 } : { opacity: 1 }"
                class="right-input-box"
              >
                <label
                  id="select_label_to"
                  class="right-coin-icon"
                  style="
                    left: 18%;
                    display: flex;
                    /* border: 1px solid red; */
                    width: 40%;
                    /* display: flex;
                    align-items: center; */
                    /* justify-content: space-between; */
                  "
                  @click.stop="
                    () => {
                      closeAllRightSelet();
                      showLeftSelet = false;
                      rightCoin.showSelet = true;
                      $el.ownerDocument.defaultView.console.log(
                        rightCoin,
                        index1,
                        rightCoinList
                      );
                    }
                  "
                >
                  <span
                    class="coin-img svgcoin"
                    :class="rightCoin.iconName"
                    style="width: 22px; height: 22px; display: block;font-weight: 700;"
                  ></span>

                  <span
                                        style="display: flex;font-weight: 700;"
                                        v-if="rightCoin.sortName.indexOf('-') > -1"
                                    >
                                        {{ rightCoin.sortName.split("-")[0] }}
                                        <span style="font-weight: 700;">({{ rightCoin.sortName.split("-")[1] }})</span>

                                        <!-- </sup></sup> -->
                                    </span>
                                    <span style="font-weight: 700;" v-else>
                                        {{ rightCoin.sortName }}
                                    </span>
                                    <span style="margin-right: 10px"></span>

                  <span style="margin-right: 10px"></span>
                  
                  <span
                    style="
                      border-bottom-color: transparent;
                      border-left-color: transparent;
                      border-right-color: transparent;
                      border-style: solid;
                      border-width: 5px 5px;
                      display: inline-block;
                      vertical-align: middle;
                      transform: translateY(28%);
                      /* margin-left: 40px; */
                    "
                  >
                  </span>
                </label>

                <input
                  class="input-amount right-coin-input"
                  type="text"
                  value=""
                  id="select_amount_to"
                  data-dir="to"
                  maxlength="18"
                  readonly="readonly"
                  style="
                    width: 82%;
                    padding-left: 0;
                    text-align: right;
                    background-color: transparent;
                    outline: none;
                    border-style: none;
                    font-family: Helvetica;
                    font-size: 22px;
                    font-weight: 700;
                    line-height: 25.3px;
                    text-align: right;
                    color: #4790f6;
                  "
                  v-model="rightCoin.amountText"
                />
              </div>
              <!-- 结果 输入框 -->
              <!-- <input
                class="input-amount right-coin-input"
                type="text"
                value=""
                id="select_amount_to"
                data-dir="to"
                maxlength="18"
                readonly="readonly"
                style="width: 82%; padding-left: 0"
                v-model="rightCoin.amountText"
              /> -->

              <!-- 点击输入显示下拉框 -->
              <!-- <label
                id="select_label_to"
                class="right-coin-icon"
                style="left: 18%"
                @click.stop="
                  () => {
                    closeAllRightSelet();
                    showLeftSelet = false;
                    rightCoin.showSelet = true;
                    $el.ownerDocument.defaultView.console.log(
                      rightCoin,
                      index1,
                      rightCoinList
                    );
                  }
                "
              >
                <span
                  class="coin-img svgcoin"
                  :class="rightCoin.iconName"
                ></span>
                <span v-if="rightCoin.sortName.indexOf('-') > -1"
                  >{{ rightCoin.sortName.split("-")[0]
                  }}<sup>{{ rightCoin.sortName.split("-")[1] }}<sup></sup></sup
                ></span>
                <span v-else>{{ rightCoin.sortName }}</span>
              </label> -->

              <span id="select_hinterror_to" class="hint error"></span>

              <div class="input-rate rev" id="select_info_to"></div>
            </div>
            <div
              class="dinput right-coin-dinput"
              style="
                border: 1px solid transparent;
                margin-top: 12px;
                height: 56px;
                background: #0000001f;
                border-radius: 5px;
              "
            >
              <input
                class="nonextra"
                :class="{
                  error:
                    !rightCoin.isValidAddress &&
                    rightCoin.address.length > 30 &&
                    !rightCoin.addressFocus,
                }"
                type="text"
                id="receive_wallet"
                :placeholder="myPlaceholder(rightCoin.sortName)"
                autocomplete="off"
                style="
                  font-size: 1em;
                  background-color: transparent;
                  text-align: left;
                  padding-left: 10px;
                  
                "
                v-model="rightCoin.address"
                @focus="
                  tmpFocusValue = rightCoin.address;
                  rightCoin.addressFocus = true;
                "
                @input="
                  rightCoin.addressFocus = true;
                  rightCoin.address.length > 30 && rightCoin.validAddress();
                "
                required
              />
              <span id="receive_wallet_error" class="hint">{{
                $t("index.addressHint")
              }}</span>
              <!-- <hr
                id="receive_wallet_line"
                :style="{
                  background: `linear-gradient(
                          to right,
                          ${colorList[leftCoin.iconName]} 0%,
                          ${colorList[rightCoin.iconName]} 100%
                        )`,
                }"
              /> -->
              <!-- <label
                class="nonextra"
                id="receive_wallet_label"
                style="text-align: center"
              >
                {{
                  $t("index.receive_wallet_label", {
                    sortName: rightCoin.sortName,
                  })
                }}</label
              > -->
            </div>
          </div>
        </div>

        <!-- 按钮 -->
        <div class="input-box my-btns" style="background-color: transparent">
          <button
            id="add_currency"
            class="exchange-submit"
            style="
              width: 50%;
              float: left;
              background: #4790f6 !important;
              border-radius: 0px;
              border-radius: 5px 0px 0px 5px;
              height: inherit;
            "
            :class="{
              disabled: rightCoinList.length > 9,
            }"
            @click.prevent="addRightCoin"
          >
            <span style="font-size: 1em; background-color: #4790f6">{{
              $t("index.add_right_coin")
            }}</span>
          </button>
          <button
            id="del_currency"
            class="exchange-submit"
            style="
              width: 50%;
              float: left;
              /* background-color: red; */
              margin-left: 5px;
              border-radius: 0px;
              background: #c33f57;

              border-radius: 0px;
              border-radius: 0 5px 5px 0;
              height: inherit;
              margin-left: 1px;
            "
            :class="{
              disabled: rightCoinList.length < 3,
            }"
            @click.prevent="delRightCoinNum"
          >
            <span style="font-size: 1em; background: #c33f57">{{
              $t("index.del_right_coin")
            }}</span>
          </button>
        </div>
      </div>

      <div style="width: 92%; margin-top: 20px;
                font-size: 14px;
                font-weight: 400;
                line-height: 16.88px;
                text-align: left;
                color: #FFFFFF;

">
        {{ $t("index.hourText") }}:
      </div>

      <!-- 选择与提交 -->
      <div
        style="
          width: 92%;
          margin-top: 12px;
          border: 1px solid #3f5e9d;
          border-radius: 16px !important;

          padding: 10px;
        "
        class="select-m"
      >
        <!-- 多选框 -->
        <div
          class="exchange-option center clrfix"
          style="margin-top: 20px; /* border: 1px solid red; */"
        >
          <div
            class="exchange-option-inner"
            style="
              /* border: 1px solid red; */
              margin-top: 16px;
              background-color: transparent;
            "
          >
            <v-select
              class="style-chooser"
              style="
                float: left;
                border: 1px solid transparent;
                background: #0000001f;
                border-radius: 5px;
              "
              :searchable="false"
              v-model="exchangeTime"
              :options="exchangeTimeArr"
            ></v-select>
          </div>
          <br />
          <span
            id="type_difference"
            style="background-color: transparent !important; color: #ffffff"
            class="exchange-option-diff"
          >
            <font>{{ $t("index.fee_hint") }}</font>
          </span>
        </div>

        <!-- ----------- -->
        <!-- 提交按钮 -->
        <div class="exchange-button center clrfix" style="margin: 23px 0">
          <button
            id="exchange_submit"
            class="exchange-submit"
            :class="{
              disabled: !isAllAddressValid,
              loading: readyToOrder,
            }"
            style="
              background: linear-gradient(270deg, #4775f6 0%, #2957d0 100%);
              border: 0.5px solid #576cf2;
              box-shadow: 0px 4px 12px 0px #1d72e767;
            "
            @click.prevent="getExchangeMake"
          >
            <span
              style="
                background-color: transparent;
                color: white;
                font-family: Bebas;
                font-size: 16px;
                font-weight: 400;
                line-height: 21.17px;
              "
              >{{ $t("index.exchange_submit") }}</span
            >
          </button>
          <br />
          <span v-if="!isPercentValid" style="color: red">{{
            $t("index.percentHint")
          }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import colorList from "@/plugins/data/colors";
import { opacity } from "@antv/x6/lib/registry/highlighter/opacity";

export default {
  data() {
    return {
      isAllAddressValid: false,
      isPercentValid: true,
      tmpFocusValue: null,
      exchangeTimeArrProxy: null,
      exchangeTimeArrIndex: 0,
      //exchangeTime: '1 '+this.$t('index.hour') + ', '+this.$t('index.fee')+': 5%, '+this.$t('index.safe1'),
      //exchangeTime: '',
      exchangeFee: "5%",
      rightCoinList: [],
      searchText: "",
      currencyList: {
        coin: [],
        token: [],
        popular: [],
      },
      showLeftSelet: false,
      isRightOnFocus: false,
      leftCoin: {
        type: "coin",
        name: "",
        sortName: "",
        iconName: "",
        amount: 0,
        min_amount: 0,
      },
      formData: {
        sendNum: 0,
        receiveNum: "0",
        type: "float",
        address: "",
      },
      exchangePriceUsd: "0",
      readyToOrder: false,
      colorList,
    };
  },
  created() {
    this.getCurrencyList();
  },
  mounted() {
    this.getNotice();
  },
  watch: {
    exchangeFee() {
      this.getExchangePrice();
    },
    // "exchangeTime":{
    // handler(newVal){
    // console.log(newval)
    // this.exchangeFee = ['5%','6%','7%','8%','9%','10%'][this.exchangeTimeArr.indexOf(newVal)];
    // }

    // },
    "$route.query": {
      handler() {
        console.log(this.$route.query);
        // 执行代码部分
        let { from, to, lang } = this.$route.query;
        if (lang) {
          this.getNotice();
        }
        // if (from && to) {
        // this.currencyList.popular.forEach((item) => {
        // if (item[1] === from) {
        // this.chooseCoin("leftCoin", item, "coin", true);
        // } else if (item[1] === to) {
        // this.chooseCoin("rightCoin", item, "coin", true);
        // }
        // });
        // this.formData.sendNum = 0;
        // this.getExchangePrice(true);
        // this.$scrollToTop();
        // }
      },
    },
  },
  methods: {
    closeAllRightSelet() {
      this.rightCoinList.forEach(function (item, index, rightCoinList) {
        rightCoinList[index].showSelet = false;
      });
    },
    checkAllAddressValid() {
      var flag = false;
      this.rightCoinList.every(function (item) {
        if (!item.isValidAddress) {
          flag = false;
          return false;
        } else {
          flag = true;
        }
        return true;
      });
      return flag;
    },
    addRightCoin() {
      if (this.rightCoinList.length > 9) {
        return;
      }

      this.rightCoinList.every(function (item) {
        if (item.percent > 1) {
          item.percent -= 1;
          return false;
        }
      });
      this.addRightCoinNum();
      var last_item = this.rightCoinList[this.rightCoinList.length - 2];

      this.chooseCoinRight(
        this.rightCoinList[this.rightCoinList.length - 1],
        [last_item.name, last_item.sortName, last_item.iconName],
        last_item.type,
        1
      );
      return;
    },
    addRightCoinNum() {
      this.rightCoinList.push({
        name: "",
        type: "",
        percent: 0,
        rate: 0,
        amount: 0,
        amountText: "",
        sortName: "",
        iconName: "",
        showSelet: false,
        address: "",
        addressFocus: false,
        isValidAddress: false,
        validAddress: function () {
          this.isValidAddress = false;
          if (this.address == "" || this.address.length < 30) {
            return;
          }
          this.validAddressApi(this);
        },
        validAddressApi: (x) => {
          this.$api
            .validAddress({
              currency: x.sortName,
              address: x.address,
            })
            .then((res) => {
              x.isValidAddress = res.data.valid;
              x.addressFocus = false;
              this.isAllAddressValid = this.checkAllAddressValid();
            });
        },
      });
    },
    delRightCoinNum() {
      if (this.rightCoinList.length < 3) {
        return;
      }
      var xxx = this.rightCoinList.pop();
      this.rightCoinList[0].percent += xxx.percent;
      this.getExchangePrice();
    },
    getNotice() {
      var ts_last = this.$cookies.get("notice_ts");
      var ts = parseInt(new Date().getTime() / 1000);
      if (!ts_last) {
        this.$cookies.set("notice_ts", ts);
      } else {
        if (ts - ts_last < 3600 * 24) {
          return;
        } else {
          this.$cookies.set("notice_ts", ts);
        }
      }
      var lang_index = this.$cookies.get("langIndex");
      this.$api.getNotice().then((res) => {
        if (res.data.show) {
          this.$modal.show("dialog", {
            title: res.data.content[lang_index][0],
            text:
              res.data.content[lang_index][1] +
              res.data.scam_list.join("<br />"),
            buttons: [
              {
                title: res.data.content[lang_index][2],
                handler: () => {
                  this.$modal.hide("dialog");
                },
              },
            ],
          });
        }
      });
    },
    getCurrencyList() {
      this.addRightCoinNum();
      this.addRightCoinNum();
      this.$api.getCurrencyList().then((res) => {
        let currencyList = res.data;
        for (let key in currencyList) {
          currencyList[key].forEach((item) => {
            item[2] = item[2][1];
          });
        }
        this.currencyList = currencyList;
        this.chooseCoinLeft(this.currencyList.popular[4], "coin", true);
        this.chooseCoinRight(
          this.rightCoinList[0],
          this.currencyList.popular[0],
          "coin",
          60,
          true
        );
        this.chooseCoinRight(
          this.rightCoinList[1],
          this.currencyList.popular[1],
          "coin",
          40,
          true
        );
        this.getExchangePrice();
      });
    },
    getExchangePrice() {
      var total_percent = 0;
      var info = [];
      this.rightCoinList.every(function (item) {
        if (item.showSelet) {
          total_percent = 0;
          return false;
        }
        if (item.percent < 1) {
          total_percent = 0;
          return false;
        }
        total_percent += item.percent;
        info.push([item.sortName, item.percent]);
        return true;
      });
      console.log(total_percent, this.rightCoinList, info);
      if (total_percent != 100) {
        this.isPercentValid = false;
        return;
      }
      this.isPercentValid = true;

      this.$api
        .getExchangePriceDeep({
          from_currency: this.leftCoin.sortName,
          to_currency: JSON.stringify(info),
          from_qty: this.leftCoin.amount,
          duration: this.exchangeTime,
          //type: this.formData.type,
          // to_qty: "",
        })
        .then((res) => {
          this.leftCoin.amount = res.data.from.amount;
          this.leftCoin.min = res.data.from.min;
          this.rightCoinList.forEach(function (to_x, i, rightCoinList) {
            rightCoinList[i].amount = res.data.to[i][1];
            rightCoinList[i].rate = res.data.to[i][2];
            rightCoinList[i].amountText = "≈" + rightCoinList[i].amount;
          });
          this.exchangePriceUsd = res.data.usd;
        });
    },
    getExchangePriceByToQty(to_qty) {
      this.$api
        .getExchangePrice({
          from_currency: this.leftCoin.sortName,
          to_currency: this.rightCoin.sortName,
          type: this.formData.type,
          to_qty,
        })
        .then((res) => {
          this.exchangePrice = res.data;
          this.formData.receiveNum = "≈" + res.data.to.amount;
          this.formData.sendNum = res.data.from.amount;
        });
    },
    chooseCoinLeft(item, type, isDefault = false) {
      var old_sortName = this.leftCoin.sortName;
      this.searchText = "";
      this.leftCoin = {
        type: type,
        name: item[0],
        sortName: item[1],
        iconName: item[2],
        amount: 0,
      };
      this.showLeftSelet = false;
      this.closeAllRightSelet();
      //this.formData.sendNum = 0;
      if (old_sortName != this.leftCoin.sortName && !isDefault) {
        this.getExchangePrice();
      }
      //this.validAddress();
    },
    chooseCoinRight(x, item, type, percent = 0, isDefault = false) {
      var old_sortName = x.sortName;
      this.searchText = "";
      x.type = type;
      x.name = item[0];
      x.sortName = item[1];
      x.iconName = item[2];
      if (percent > 0) {
        x.percent = percent;
      }

      this.showLeftSelet = false;
      this.closeAllRightSelet();
      this.formData.sendNum = 0;
      x.validAddress();

      if (old_sortName != x.sortName && !isDefault) {
        this.getExchangePrice();
      }
    },
    changeSendNum() {
      if (this.leftCoin.amount < this.leftCoin.min - 0) {
        this.leftCoin.amount = this.leftCoin.min;
      }
      this.getExchangePrice();
    },
    getExchangeMake() {
      if (
        !this.checkAllAddressValid() ||
        !this.isPercentValid ||
        this.readyToOrder
      ) {
        return;
      }
      var right_arr = [];
      this.rightCoinList.forEach(function (item) {
        right_arr.push([item.sortName, item.percent, item.address]);
      });
      this.readyToOrder = true;
      this.$api
        .getExchangeMakeDeep({
          from_currency: this.leftCoin.sortName,
          to_currency: JSON.stringify(right_arr),
          from_qty: this.leftCoin.amount,
          duration: this.exchangeTime,
          fee: this.exchangeFee,
        })
        .then((res) => {
          this.$router.push({
            path: "/main/orderdeep",
            query: { orderId: res.data.order_id },
          });
        })
        .finally(() => {
          this.readyToOrder = false;
        });
    },
    exchangeCoin() {
      let temporary = { ...this.leftCoin };
      this.leftCoin = { ...this.rightCoin };
      this.rightCoin = { ...temporary };
      this.formData.sendNum = this.formData.receiveNum.replace("≈", "") - 0;
      this.getExchangePrice();
      this.validAddress();
    },
    //自定义 placeholder
    myPlaceholder: function (name) {
      return this.$t("index.receive_wallet_label", {
        sortName: name,
      });
    },
  },
  computed: {
    currencyListPopularSearch: function () {
      let arr = [];
      this.currencyList.popular.forEach((item) => {
        if (
          item[0].match(new RegExp(this.searchText, "ig")) ||
          item[1].match(new RegExp(this.searchText, "ig"))
        ) {
          arr.push(item);
        }
      });
      return arr;
    },
    currencyListCoinSearch: function () {
      let arr = [];
      this.currencyList.coin.forEach((item) => {
        if (
          item[0].match(new RegExp(this.searchText, "ig")) ||
          item[1].match(new RegExp(this.searchText, "ig"))
        ) {
          arr.push(item);
        }
      });
      return arr;
    },
    currencyListTokenSearch: function () {
      let arr = [];
      this.currencyList.token.forEach((item) => {
        if (
          item[0].match(new RegExp(this.searchText, "ig")) ||
          item[1].match(new RegExp(this.searchText, "ig"))
        ) {
          arr.push(item);
        }
      });
      return arr;
    },
    exchangeTimeArr: {
      get() {
        this.exchangeTimeArrProxy = [
          "1 " +
            this.$t("index.hour") +
            ", " +
            this.$t("index.fee") +
            ": 5%, " +
            this.$t("index.safe1"),
          "2 " +
            this.$t("index.hours") +
            ", " +
            this.$t("index.fee") +
            ": 6%, " +
            this.$t("index.safe1"),
          "4 " +
            this.$t("index.hours") +
            ", " +
            this.$t("index.fee") +
            ": 7%, " +
            this.$t("index.safe1"),
          "8 " +
            this.$t("index.hours") +
            ", " +
            this.$t("index.fee") +
            ": 8%, " +
            this.$t("index.safe2"),
          "12 " +
            this.$t("index.hours") +
            ", " +
            this.$t("index.fee") +
            ": 9%, " +
            this.$t("index.safe2"),
          "24 " +
            this.$t("index.hours") +
            ", " +
            this.$t("index.fee") +
            ": 10%, " +
            this.$t("index.safe3"),
        ];
        return [
          "1 " +
            this.$t("index.hour") +
            ", " +
            this.$t("index.fee") +
            ": 5%, " +
            this.$t("index.safe1"),
          "2 " +
            this.$t("index.hours") +
            ", " +
            this.$t("index.fee") +
            ": 6%, " +
            this.$t("index.safe1"),
          "4 " +
            this.$t("index.hours") +
            ", " +
            this.$t("index.fee") +
            ": 7%, " +
            this.$t("index.safe1"),
          "8 " +
            this.$t("index.hours") +
            ", " +
            this.$t("index.fee") +
            ": 8%, " +
            this.$t("index.safe2"),
          "12 " +
            this.$t("index.hours") +
            ", " +
            this.$t("index.fee") +
            ": 9%, " +
            this.$t("index.safe2"),
          "24 " +
            this.$t("index.hours") +
            ", " +
            this.$t("index.fee") +
            ": 10%, " +
            this.$t("index.safe3"),
        ];
      },
      set(newVal) {},
    },
    exchangeTime: {
      get() {
        return this.exchangeTimeArrProxy[this.exchangeTimeArrIndex];
      },
      set(newVal) {
        this.exchangeTimeArrIndex = newVal
          ? this.exchangeTimeArrProxy.indexOf(newVal)
          : 0;
        this.exchangeFee = ["5%", "6%", "7%", "8%", "9%", "10%"][
          this.exchangeTimeArrProxy.indexOf(newVal)
        ];
        //this.exchangeTime  = newVal;
      },
    },
  },
};
</script>

<style lang="less">
* {
  box-sizing: border-box;
}

label.radioselect > input:checked + span {
  background-color: #4790f6;
}
label.radioselect > span {
  border-style: none;
}
.input-wabbr > input {
  border-bottom: 0px;
  // background-color: red;
  text-align: right;
  padding: 0;
  // border: 1px solid red !important;
}
.input-wabbr.toleft > input {
  padding-right: 0;
}

.container-1 {
  .pc-box {
    display: flex !important;
  }
  .mobile-box {
    display: none !important;
  }

  .select-m {
    background: linear-gradient(
      180deg,
      #2a467e 0%,
      #253e74 32.15%,
      #1c2d5f 100%
    );
  }
  @media screen and (max-width: 500px) {
    .from-box {
      height: 44px !important;
    }
    .mobile-input-height {
      margin-top: 10px !important;

      .left-height {
        height: 44px !important;
        padding: 0;
      }
      .right-input-box {
        height: 44px !important;
        padding: 0;
      }
    }
    .dinput {
      height: 44px !important;
    }

    .select-m {
      width: 100% !important;
    }
    .my-btns {
      height: 44px !important;
    }

    button.exchange-submit > span {
      padding: 8px !important;
    }

    .box-container .input-container {
      padding: 12px !important;
    }
  }
  .input-wabbr > label::before,
  .input-wabbr > label:after {
    border-style: none !important;
  }
  width: 80%;
  // border: 1px solid red;

  margin: 0 auto;
  margin-top: 47px;

  .box-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;

    > img {
      position: absolute;
      top: 0;
      width: 50%;
    }
    // > div {
    //   width: 50%;
    //   height: 570px;
    // }

    .input-container {
      // border: 1px solid red !important;
      margin-top: 90px;
      width: 92%;
      .exchange-option-inner {
        margin-top: 20px;
        border: 2px solid #4b658a;
        border-radius: 10px;
        > input {
          outline: none;
          border-style: none;
        }
        .label.radioselect > input:checked {
          background-color: red !important;
        }
      }
      background: linear-gradient(
        180deg,
        #2a467e 0%,
        #253e74 32.15%,
        #1c2d5f 100%
      );

      .col {
        box-sizing: border-box;
        background: #0000001f;
        border-radius: 10px;
        padding: 0 10px;
        height: 56px;
        padding-bottom: 15px;
        margin-top: 10px;
      }

      /**------------ */
      // border: 1px solid #3f5e9d;
      box-shadow: 0 0 0 1px #3e5686;
      border-radius: 15px;
      padding: 32px;
      display: flex;
      flex-direction: column;
      .rate {
        
        font-size: 12px;
        font-weight: 400;
        line-height: 14.47px;
        color: #ffffff;
      }

      .input-box {
        width: 100%;
        height: 56px;
        background: #0000001f;
        margin-top: 10px;
        border-radius: 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px;
        position: relative;
        .ui-select-outer {
          position: absolute;
          top: 0px;
          left: 0;
          width: 100%;
        }

        .ui-select-search {
          border-color: #1c2d5f !important;
        }
        .left {
          display: flex;
          justify-content: start;
          align-items: center;
        }
        .right {
          background-color: transparent;
          outline: none;
          border-style: none;

          height: 100%;
          width: 60%;

          font-family: Bebas;
          font-size: 22px;
          font-weight: 400;
          line-height: 29.11px;
          color: #4790f6;
          text-align: right;
        }

        .address {
          width: 100%;
          height: 100%;
          background-color: transparent;
          outline: none;
          border-style: none;

          ;
          font-size: 14px;
          font-weight: 400;
          line-height: 16.88px;
          text-align: left;
          color: #7992c1;
        }
      }

      .change-btn {
        width: 32px;
        min-height: 32px;
        background: #4790f6;
        border-radius: 50%;
        margin: 20px auto 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer !important;
        z-index: 10;
        &:active {
          opacity: 0.5;
        }
      }

      .rate-select {
        width: 100%;
        height: 44px;
        border: 2px solid #4b658a;
        margin-top: 24px;
        display: flex;
        align-items: center;
        border-radius: 14px;
        overflow: hidden;
        > div {
          flex: 1;
          display: flex;
          justify-content: center;
          align-items: center;
          height: 100%;
        }

        .active {
          background-color: #4790f6;
        }
      }

      .text-info {
        
        font-size: 12px;
        font-weight: 400;
        line-height: 14.47px;
        text-align: center;
        color: #ffffff;
        padding: 0 20px;
        margin-top: 12px;
      }

      .submit {
        width: 100%;
        height: 52px;
        border: 0.5px 0px 0px 0px;
        background: linear-gradient(270deg, #4775f6 0%, #2957d0 100%);
        border: 0.5px solid #576cf2;
        margin-top: 42px;
        border-radius: 25px;
        box-shadow: 0px 4px 12px 0px #1d72e767;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        > span {
          font-family: Bebas;
          font-size: 16px;
          font-weight: 400;
          line-height: 21.17px;

          color: #ffffff;
        }
      }
    }

    .logo-container {
      background-image: url("/img/logo-bg-1.png");
      background-repeat: no-repeat;

      background-size: contain;
      margin-left: 40px;
    }
  }
}
.img-wt {
  position: absolute;
  top: 10px;
  left: -48px;
  width: 115%;
  overflow: hidden;
  max-width: 100vw;
}

.nonextra {
  &::placeholder {
    color: #7992c1;

    font-size: 14px;
    font-weight: 400;
    line-height: 16.88px;
    text-align: left;
  }
}
@media screen and (max-width: 600px) {
  .img-wt {
    top: 24px;
    left: 0px;
    overflow: hidden;
  }
  .img-wt >img{
   width: 104vw;
  }
  .container-1 {
    width: 100%;
    #select_label_from > :first-child {
      margin-left: 0px;
      min-width: 0;
    }
    .my-border{
      margin-left: 40px;
    }
    .box-container {
      > img {
        width: 100%;
      }
      .input-container {
        width: 100%;
      }
    }
  }
}

.vs__dropdown-menu {
  border-radius: 10px;
}
.vs__dropdown-option {
  background: #0d204a;
  
  font-size: 16px;
  font-weight: 400;
  line-height: 19.3px;
  text-align: left;
  color: #8c91af;
  height: 35px;
  line-height: 28px;
  &:hover {
    background: #1d315d;
    color: #4790f6;
  }
}

.my-border {
  border-bottom-color: transparent;
  border-left-color: transparent;
  border-right-color: transparent;
  border-style: solid;
  border-width: 8px 5px;
  display: inline-block;
  vertical-align: middle;
  transform: translateY(28%);
  margin-left: 10px;
}
#left-coin-area {
  margin-left: 0 !important;
  margin-right: 0 !important;
}
.ic-1{
  font-weight: 700;
}

.input-wabbr > input{
  font-weight: 700;
}
</style>
